import React, { useEffect, useState } from "react"

import "./styles.scss"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { fetchMyOffers, myOffers, myOffersLoading, resetMyOffers } from "../../store/slices/offers"
import Loader from "../../components/shared/loader/Loader"
import OffersEmptyState from "../../components/offers-empty-state/OffersEmptyState"
import { getUser } from "../../store/slices/auth"
import CardsList from "../../components/shared/card-list/CardsList"
import { FreelancerType } from "../../enums"
import AddOfferBtn from "../../components/shared/add-offer-btn/AddOfferBtn"
import LoadMoreButton from "../../components/shared/load-more-btn/LoadMoreButton"

const MyOffersPage = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const offersLoading = useAppSelector(myOffersLoading)
  const offers = useAppSelector(myOffers)
  // const offerCounts = useAppSelector(myOffersCounts) // Pagination state
  const [page, setPage] = useState(1)
  const [newOffers, setNewOffers] = useState(offers)
  const [newOffersLoading, setNewOffersLoading] = useState(false)
  const perPage = 10
  useEffect(() => {
    dispatch(fetchMyOffers({ telegramId: user.telegramId, type: "personal", page, perPage })).then(
      (res) => {
        setNewOffersLoading(false)
        setNewOffers(res.payload.offers)
      },
    )
  }, [page, user.telegramId, perPage, dispatch])
  const handleLoadMore = () => {
    setNewOffersLoading(true)
    setPage((prevPage: any) => prevPage + 1)
  }

  useEffect(() => {
    return () => {
      dispatch(resetMyOffers())
    }
  }, [])
  if (offersLoading && !offers.length) {
    return <Loader />
  }

  return (
    <div className={"my-offers-page"}>
      <div className={"my-offers-page--heading d-flex-centered container-padding"}>
        <h1>Мои офферы</h1>
      </div>
      {user.type === FreelancerType.FREELANCER ? (
        <div className={"container-padding"}>
          <AddOfferBtn type={"button"} />
        </div>
      ) : null}
      <div
        className={
          "my-offers-page__list__heading d-flex-space-between-aligned m-t-16 container-padding"
        }
      >
        <p className={"my-offers-page__list__heading--title"}>Список ваших офферов</p>
        {/*<p className={"my-offers-page__list__heading--count"}>*/}
        {/*  {offers.length ? (*/}
        {/*    <span>*/}
        {/*      <span className={"my-offers-page__list__heading--count-live"}>*/}
        {/*        {offerCounts?.VERIFIED}*/}
        {/*      </span>*/}
        {/*      /*/}
        {/*      <span className={"my-offers-page__list__heading--count-moderation"}>*/}
        {/*        {offerCounts?.REQUESTED_MODERATION}*/}
        {/*      </span>*/}
        {/*      /*/}
        {/*      <span className={"my-offers-page__list__heading--count-rejected"}>*/}
        {/*        {offerCounts?.WAITING_FOR_CHANGES}*/}
        {/*      </span>*/}
        {/*    </span>*/}
        {/*  ) : (*/}
        {/*    0*/}
        {/*  )}*/}
        {/*</p>*/}
      </div>
      {offers.length ? <CardsList list={offers} isMyOffer /> : <OffersEmptyState />}
      {newOffers.length && newOffers.length === perPage ? (
        <div className={"container-padding"}>
          <LoadMoreButton handleClick={handleLoadMore} isLoading={newOffersLoading} />
        </div>
      ) : null}
    </div>
  )
}

export default MyOffersPage
