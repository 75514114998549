import React, { useMemo } from "react"
import { useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../store/slices/auth"
import { FreelancerType } from "../../enums"
import { NAVIGATION_LIST } from "../../constants"
import { NavLink, useLocation } from "react-router-dom"
import "./Navigation.scss"

const Navigation = () => {
  const user = useAppSelector(getUser)
  const { pathname } = useLocation()
  const navigationList = useMemo(() => {
    if (user.type && user.type === FreelancerType.FREELANCER) {
      return NAVIGATION_LIST
    } else {
      return NAVIGATION_LIST.filter((item) => item.id !== 1)
    }
  }, [user.type])

  return (
    <div className={"navigation d-flex-center-aligned justify-content-center"}>
      <div className={"navigation-content d-flex-center-aligned gap-32"}>
        {navigationList.map((item) => (
          <NavLink
            key={item.id}
            to={item.path}
            className={({ isActive }) =>
              `d-flex-column-centered gap-4 navigation-link ${isActive ? "navigation-active-link" : ""}`
            }
          >
            {item.icon}
            <p>{item.name}</p>
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default Navigation
