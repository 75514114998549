import React from "react"
import PageWithStatuses from "../../components/page-with-statuses/PageWithStatuses"

const FavoritesPage = () => {
  return (
    <div className={"page-bottom-padding page-top-padding"}>
      <PageWithStatuses status={"favorite"} />
    </div>
  )
}

export default FavoritesPage
