import React from "react"
import { useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { IObjectKeys } from "../../types"
import "./SubCategories.scss"
import { BASE_URL } from "../../constants"
import { getSubCategories } from "../../store/slices/categories"

const SubCategories = ({
  onSubCategoryClick,
  subCategoriesRef,
}: {
  onSubCategoryClick: (category: string) => void
  subCategoriesRef: React.RefObject<HTMLDivElement>
}) => {
  const subCategories = useAppSelector(getSubCategories)
  return (
    <div className={"sub-categories"}>
      <h2 className={"sub-categories--title"}>Популярные категории</h2>
      <div className={"sub-categories__list d-flex gap-8"} ref={subCategoriesRef}>
        {subCategories.map((subCategory: IObjectKeys) => (
          <div
            key={subCategory.id}
            className={"sub-categories__list__item d-flex-column"}
            onClick={() => onSubCategoryClick(subCategory.name)}
          >
            <div className={"sub-categories__list__item--img"}>
              <img src={`${BASE_URL}/${subCategory.imageUrl}`} alt={subCategory.imageUrl} />
            </div>
            <p className={"sub-categories__list__item--name"}>{subCategory.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SubCategories
