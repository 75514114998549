import React from "react"
import SearchIcon from "../../assets/images/svg/search-icon.svg?react"
import "./Search.scss"

interface SearchProps {
  searchText: string
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Search: React.FC<SearchProps> = ({ searchText, handleSearch }) => {
  return (
    <div className={"search-wrapper flex-1"}>
      <SearchIcon />
      <input
        className={"search-wrapper--input"}
        type='text'
        placeholder={"Начните ввод для поиска"}
        value={searchText}
        onChange={handleSearch}
      />
    </div>
  )
}

export default Search
