import React, { useCallback, useEffect, useRef, useState } from "react"
import "./AddOfferForm.scss"
import AddOfferBtn from "../shared/add-offer-btn/AddOfferBtn"
import CategoriesSelect from "./categories-select/CategoriesSelect"
import PortfolioUploading from "./portfolio-uploading/PortfolioUploading"
import ThumbnailUploading from "./thumbnail-uploading/ThumbnailUploading"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import {
  editOffer,
  getSingleOffer,
  submitOffer,
  updateSingleOffer,
} from "../../store/slices/offers"
import { getIsFormFocused, getUser, setIsFormFocused } from "../../store/slices/auth"
import { useNavigate } from "react-router-dom"

const AddOfferForm = ({
  setSuccessVisible,
  isEditMode,
}: {
  setSuccessVisible?: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode?: boolean
}) => {
  const isFocused = useAppSelector(getIsFormFocused)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const editOfferData = useAppSelector(getSingleOffer)
  const [thumbnailId, setThumbnailId] = useState<number | null>(null)
  const [portfolioIds, setPortfolioIds] = useState<number[]>([])
  const [formErrors, setFormErrors] = useState<any>({})
  const [subCategoryId, setSubCategoryId] = useState<number | null>(null)
  const user = useAppSelector(getUser)
  const formRef = useRef<HTMLFormElement | null>(null)

  const validateForm = (formData: FormData) => {
    let errors: any = {}
    const title = formData.get("title")
    const description = formData.get("description")
    const priceFrom = parseFloat(formData.get("priceFrom") as string)
    const priceTo = parseFloat(formData.get("priceTo") as string)
    const timeFrom = parseFloat(formData.get("timeFrom") as string)
    const timeTo = parseFloat(formData.get("timeTo") as string)
    // Title validation
    if (!title) {
      errors.title = "Название оффера обязательно."
    }

    // Description validation
    if (!description) {
      errors.description = "Описание оффера обязательно."
    }

    // Price validation
    if (isNaN(priceFrom) || isNaN(priceTo)) {
      errors.price = "Введите корректные значения для стоимости."
    } else if (priceFrom > priceTo) {
      errors.price = "Стоимость 'От' не может быть больше стоимости 'До'."
    }

    // Time validation
    if (isNaN(timeFrom) || isNaN(timeTo)) {
      errors.time = "Введите корректные значения для времени выполнения."
    } else if (timeFrom > timeTo) {
      errors.time = "Время 'От' не может быть больше времени 'До'."
    }

    // Thumbnail validation
    if (thumbnailId === null || thumbnailId === undefined) {
      errors.thumbnail = "Загрузите обложку для оффера."
    }
    if (!subCategoryId) {
      errors.subCategory = "Выберите подходящую категорию"
    }

    return errors
  }
  const resetForm = () => {
    setThumbnailId(null)
    setPortfolioIds([])
    setSubCategoryId(null)
    setFormErrors([])
    window.scrollTo(0, 0)
    setSuccessVisible && setSuccessVisible(true)
  }
  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      setFormErrors([])
      const formData = new FormData(event.target as HTMLFormElement)
      const errors = validateForm(formData)

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors)
        return
      } else {
        setFormErrors([])
      }
      const offerData = {
        title: `${formData.get("title")}`,
        description: formData.get("description"),
        priceFrom: Number(formData.get("priceFrom")),
        priceTo: Number(formData.get("priceTo")),
        timeFrom: Number(formData.get("timeFrom")),
        timeTo: Number(formData.get("timeTo")),
        thumbnailId,
        portfolio: portfolioIds,
        subCategoryId,
      }
      if (isEditMode) {
        dispatch(editOffer({ telegramId: user.telegramId, offerData, offerId: editOfferData?.id }))
          .then((res) => {
            dispatch(updateSingleOffer(res?.payload))
            navigate(-1)
          })
          .catch((error) => {
            console.error("Error submitting offer:", error)
          })
      } else {
        dispatch(submitOffer({ telegramId: user.telegramId, offerData }))
          .then(() => {
            resetForm()
            ;(event.target as HTMLFormElement).reset()
          })
          .catch((error) => {
            console.error("Error submitting offer:", error)
          })
      }
    },
    [formErrors, thumbnailId, portfolioIds, subCategoryId, isEditMode, editOfferData],
  )
  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      const target = event.target as HTMLElement
      dispatch(setIsFormFocused(true))
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }

    const handleBlur = () => {
      dispatch(setIsFormFocused(false))
    }

    const formElement = formRef.current
    const inputs = formElement?.querySelectorAll("input, textarea")

    inputs?.forEach((input: any) => {
      input.addEventListener("focus", handleFocus)
      input.addEventListener("blur", handleBlur)
    })

    return () => {
      inputs?.forEach((input: any) => {
        input.removeEventListener("focus", handleFocus)
        input.removeEventListener("blur", handleBlur)
      })
    }
  }, [])

  return (
    <div className={"d-flex-column gap-12"}>
      <form
        className={`add-offer-form d-flex-column gap-12 ${isFocused ? "add-offer-form-focused" : ""}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <ThumbnailUploading
          setThumbnailId={setThumbnailId}
          editMode={isEditMode}
          defaultValue={editOfferData?.thumbnail}
        />
        {formErrors.thumbnail && <p className='form-error'>{formErrors.thumbnail}</p>}

        <div className={"add-offer-form__item d-flex-column gap-12"}>
          <p className={"add-offer-form__item--title"}>Портфолио</p>
          <PortfolioUploading
            setPortfolioIds={setPortfolioIds}
            defaultValue={editOfferData?.offerPortfolio}
            editMode={isEditMode}
          />
        </div>
        <div className={"add-offer-form__item d-flex-column gap-12"}>
          <p className='add-offer-form__item--title'>
            Выберите категорию <span className='add-offer-form__item--title--required'>*</span>
          </p>
          <CategoriesSelect
            setSubCategoryId={setSubCategoryId}
            editMode={isEditMode}
            defaultValue={editOfferData?.subCategory}
          />
          {formErrors.subCategory && <p className='form-error'>{formErrors.subCategory}</p>}
        </div>

        <div className={"add-offer-form__item d-flex-column gap-12"}>
          <label className={"add-offer-form__item--title"} htmlFor={"title"}>
            Название оффера <span className={"add-offer-form__item--title--required"}>*</span>
          </label>
          <input
            type='text'
            name='title'
            id='title'
            className={"add-offer-form__item-text-input"}
            placeholder={"Разработка...."}
            defaultValue={editOfferData?.title}
          />
          {formErrors.title && <p className='form-error'>{formErrors.title}</p>}
        </div>

        <div className={"add-offer-form__item d-flex-column gap-12"}>
          <label className={"add-offer-form__item--title"} htmlFor={"description"}>
            Описание оффера <span className={"add-offer-form__item--title--required"}>*</span>
          </label>
          <textarea
            name='description'
            id='description'
            className={"add-offer-form__item-text-textarea"}
            placeholder={"Макс.сим: 1000"}
            defaultValue={editOfferData?.description}
          />
          {formErrors.description && <p className='form-error'>{formErrors.description}</p>}
        </div>

        <div className={"add-offer-form__item d-flex-column gap-12"}>
          <p className={"add-offer-form__item--title"}>
            Стоимость <span className={"add-offer-form__item--title--required"}>*</span>
          </p>
          <div className={"d-flex-center-aligned gap-12"}>
            <input
              type='number'
              name='priceFrom'
              id='priceFrom'
              className={
                "add-offer-form__item-text-input add-offer-form__item-text-input-number flex-1"
              }
              placeholder={"От"}
              inputMode='numeric'
              defaultValue={editOfferData?.priceFrom}
            />
            <input
              type='number'
              name='priceTo'
              id='priceTo'
              className={
                "add-offer-form__item-text-input add-offer-form__item-text-input-number flex-1"
              }
              placeholder={"До"}
              inputMode='numeric'
              defaultValue={editOfferData?.priceTo}
            />
          </div>
          {formErrors.price && <p className='form-error'>{formErrors.price}</p>}
        </div>

        <div className={"add-offer-form__item d-flex-column gap-12"}>
          <p className={"add-offer-form__item--title"}>
            Время выполнения <span className={"add-offer-form__item--title--white"}>(часов)</span>
            <span className={"add-offer-form__item--title--required"}>*</span>
          </p>
          <div className={"d-flex-center-aligned gap-12"}>
            <input
              type='number'
              name='timeFrom'
              id='timeFrom'
              className={
                "add-offer-form__item-text-input add-offer-form__item-text-input-number flex-1"
              }
              placeholder={"От"}
              inputMode='numeric'
              defaultValue={editOfferData?.timeFrom}
            />
            <input
              type='number'
              name='timeTo'
              id='timeTo'
              className={
                "add-offer-form__item-text-input add-offer-form__item-text-input-number flex-1"
              }
              placeholder={"До"}
              inputMode='numeric'
              defaultValue={editOfferData?.timeTo}
            />
          </div>
          {formErrors.time && <p className='form-error'>{formErrors.time}</p>}
        </div>
        <AddOfferBtn type={"submit"} text={isEditMode ? "Сохранить" : "Добавить оффер"} hideIcon />
      </form>
    </div>
  )
}

export default AddOfferForm
