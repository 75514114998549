import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { checkUser, setUserInfo } from "../../store/slices/auth"
import Loader from "../../components/shared/loader/Loader"

const BaseLayout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { loading, user } = useAppSelector((state) => state.auth) // Get loading and user state from Redux
  const { pathname } = useLocation()
  useEffect(() => {
    if (window?.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp
      tg.ready()
      tg.expand()
      window.Telegram.WebApp.disableVerticalSwipes()
      const userInfo = tg.initDataUnsafe?.user
      const referralTelegramId = tg?.initDataUnsafe?.start_param || null
      if (userInfo) {
        const data = {
          telegramId: `${userInfo?.id}`,
          firstName: userInfo?.first_name,
          lastName: userInfo?.last_name,
          username: userInfo?.username,
          languageCode: userInfo?.language_code,
          allowsWriteToPm: userInfo?.allows_write_to_pm,
        }

        dispatch(setUserInfo({ ...data, referralTelegramId }))
        dispatch(checkUser({ ...data }))
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (user) {
      if (user.type === null) {
        navigate("/")
      } else {
        if (pathname === "/") {
          navigate("/catalog")
        }
      }
    }
  }, [user, pathname])

  if (loading && !user) {
    return <Loader />
  }
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default BaseLayout
