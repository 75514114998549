import CatalogIcon from "../assets/images/svg/catalog.svg?react"
import OffersIcon from "../assets/images/svg/offers.svg?react"
import MoreIcon from "../assets/images/svg/more.svg?react"

export const BASE_API_URL = import.meta.env.VITE_API_URL
export const BASE_URL = import.meta.env.VITE_BASE_URL

export const NAVIGATION_LIST = [
  { id: 0, name: "Каталог", icon: <CatalogIcon />, path: "/catalog" },
  { id: 1, name: "Офферы", icon: <OffersIcon />, path: "/offers" },
  { id: 2, name: "Ещё", icon: <MoreIcon />, path: "/more" },
]
