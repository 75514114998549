import React, { useCallback, useEffect } from "react"
import VerifiedIcon from "../../assets/images/svg/verify-icon.svg?react"
import LeftArrow from "../../assets/images/svg/left-arrow.svg?react"
import GuardIcon from "../../assets/images/svg/profile-cover.svg?react"
import TelegramIcon from "../../assets/images/svg/telegram-icon.svg?react"
import DateIcon from "../../assets/images/svg/date-icon.svg?react"
import RightArrowIcon from "../../assets/images/svg/white-right-arrow.svg?react"
import SuitcaseIcon from "../../assets/images/svg/suitcase-single.svg?react"
import { useLocation, useNavigate } from "react-router-dom"
import "./styles.scss"
import {
  fetchFreelancer,
  getFreelancer,
  getFreelancerLoading,
  resetFreelancer,
} from "../../store/slices/auth"
import { resetCatalogOffers } from "../../store/slices/offers"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import Loader from "../../components/shared/loader/Loader"
import { BASE_URL } from "../../constants"
import dayjs from "dayjs"

const FreelancerProfile = () => {
  const freelancer = useAppSelector(getFreelancer)
  const loading = useAppSelector(getFreelancerLoading)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const telegramId = new URLSearchParams(search).get("telegramId")
  useEffect(() => {
    dispatch(fetchFreelancer(telegramId || ""))
    return () => {
      dispatch(resetCatalogOffers())
      dispatch(resetFreelancer())
    }
  }, [])

  const handleClickOnOtherOffers = useCallback(() => {
    navigate(`/other-offers?telegramId=${freelancer.telegramId}`)
  }, [freelancer])

  if (loading) {
    return <Loader />
  }

  return (
    <div className={"freelancer-profile page-bottom-padding"}>
      <div className={"d-flex-center-aligned profile-page__heading gap-12"}>
        <button
          className={"d-flex-centered add-offer__heading--go-back"}
          onClick={() => navigate(-1)}
        >
          <LeftArrow />
        </button>
        <div className={"d-flex-center-aligned profile-page__heading--info gap-8"}>
          <VerifiedIcon />
          <p>{freelancer.username}</p>
        </div>
      </div>
      <div className={"profile-page__picture d-flex-column"}>
        <div className={"profile-page__picture--cover"}>
          <GuardIcon />
        </div>
        <div className={"profile-page__picture--img"}>
          <img src={`${BASE_URL}/${freelancer?.avatarUrl}`} alt='avatar' />
        </div>
      </div>
      <div className={"freelancer-profile__content"}>
        <div className={"freelancer-profile__content-wrapper"}>
          <div className={"freelancer-profile__content__body d-flex-column"}>
            {freelancer?.allowsWriteToPm ? (
              <button
                className={"d-flex-center-aligned gap-6 offer-user-profile__user-pm-btn"}
                onClick={() => {
                  const url = `https://t.me/${freelancer.username}`
                  window.location.href = url
                }}
              >
                <TelegramIcon />
                <span>Написать</span>
              </button>
            ) : null}
            <div className={"freelancer-profile__content__body__dates"}>
              <div
                className={
                  "d-flex-space-between-aligned freelancer-profile__content__body__dates__item"
                }
              >
                <div
                  className={
                    "d-flex-center-aligned gap-16 freelancer-profile__content__body__dates__item--text"
                  }
                >
                  <DateIcon />
                  <p>Дата регистрации:</p>
                </div>
                <p className={"freelancer-profile__content__body__dates__item--date"}>
                  {dayjs(freelancer.updatedAt || freelancer.createdAt).format("DD.MM.YYYY")}г
                </p>
              </div>
            </div>
            <div className={"freelancer-profile__content__body__skills"}>
              <span className={"freelancer-profile__content__body__skills--title"}>Навыки:</span>
              <div
                className={
                  "d-flex-centered-wrap gap-4 freelancer-profile__content__body__skills__list"
                }
              >
                {freelancer?.skills.map((skill: string, index: number) => (
                  <div
                    key={index}
                    className={
                      "d-flex-centered freelancer-profile__content__body__skills__list--item"
                    }
                  >
                    <span>{skill}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={"d-flex-column gap-16"}>
          <button
            className={"freelancer-profile__content--action-btn d-flex-space-between-aligned"}
            onClick={handleClickOnOtherOffers}
          >
            <span className={"d-flex-center-aligned gap-6"}>
              <SuitcaseIcon />
              <span>Офферы фрилансера</span>
            </span>
            <RightArrowIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default FreelancerProfile
