import axios, { AxiosInstance } from "axios"

// Optionally, define the structure of your API response
interface ApiResponse<T> {
  data: T
  status: number
  message?: string
}

// Create an axios instance
const api: AxiosInstance = axios.create({
  baseURL: "https://api.example.com", // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
})

// Optional: Interceptor to log requests or handle errors globally
api.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.error("API Error:", error)
    return Promise.reject(error)
  },
)

export default api
