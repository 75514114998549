import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import CardsList from "../../components/shared/card-list/CardsList"
import Categories from "../../components/categories/Categories"
import SubCategories from "../../components/sub-categories/SubCategories"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../store/slices/auth"
import { FreelancerType } from "../../enums"
import AddOfferBtn from "../../components/shared/add-offer-btn/AddOfferBtn"
import {
  fetchMyOffers,
  fetchOffersWithSearch,
  getCatalogOffers,
  getCatalogOffersLoading,
  getRefetchOffers,
  getSearchOffers,
  getSearchOffersLoading,
  resetCatalogOffers,
  resetSearchOffers,
} from "../../store/slices/offers"
import Loader from "../../components/shared/loader/Loader"
import OffersEmptyState from "../../components/offers-empty-state/OffersEmptyState"
import LoadMoreButton from "../../components/shared/load-more-btn/LoadMoreButton"
import Search from "../../components/catalog-search/Search"
import { debounced500 } from "../../utils"
import RingLoader from "../../components/shared/loader/ring-loader/RingLoader"
import HeartIcon from "../../assets/images/svg/search-heart-icon.svg?react"
import LeftArrow from "../../assets/images/svg/left-arrow.svg?react"
import { useNavigate } from "react-router-dom"
import "./styles.scss"

const MainPage = () => {
  const navigate = useNavigate()
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const catalogOffers = useAppSelector(getCatalogOffers)
  const catalogOffersLoading = useAppSelector(getCatalogOffersLoading)
  const searchOffers = useAppSelector(getSearchOffers)
  const searchOffersLoading = useAppSelector(getSearchOffersLoading)
  const [page, setPage] = useState(1)
  const [newOffers, setNewOffers] = useState(catalogOffers)
  const [newOffersLoading, setNewOffersLoading] = useState(false)
  const perPage = 3
  const [search, setSearch] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const refetchOffers = useAppSelector(getRefetchOffers)
  const subCategoriesRef = useRef<HTMLDivElement>(null)

  const offersList = useMemo(() => {
    if (isSearching) {
      return searchOffers
    } else {
      return catalogOffers
    }
  }, [searchOffers, catalogOffers, searchOffersLoading, catalogOffersLoading, isSearching])
  const isFreelancer = useMemo(() => {
    return user.type === FreelancerType.FREELANCER
  }, [user])

  const handleFetchRandomOffers = (pageNum: number) => {
    dispatch(
      fetchMyOffers({ telegramId: user.telegramId, type: "random", page: pageNum, perPage }),
    ).then((res) => {
      setNewOffersLoading(false)
      setNewOffers(res.payload.offers)
    })
  }

  const handleFetchSearchOffers = (pageNum: number, searchValue: string) => {
    dispatch(
      fetchOffersWithSearch({
        telegramId: user.telegramId,
        type: "search",
        page: pageNum,
        perPage,
        search: searchValue,
      }),
    ).then((res) => {
      setNewOffersLoading(false)
      setNewOffers(res.payload.offers)
    })
  }
  const handleLoadMore = useCallback(() => {
    setNewOffersLoading(true)
    setPage((prevPage: any) => prevPage + 1)
    if (!isSearching) {
      handleFetchRandomOffers(page + 1)
    } else {
      handleFetchSearchOffers(page + 1, search)
    }
  }, [page, isSearching, search])

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      setPage(1)
      dispatch(resetSearchOffers())
      if (e.target.value === "") {
        setIsSearching(false)
      } else {
        setIsSearching(true)
      }
      debounced500(() => {
        // dispatch(resetCatalogOffers())
        handleFetchSearchOffers(1, e.target.value)
      })
    },
    [perPage, user, page],
  )

  const onSubCategoryClick = (category: string) => {
    setSearch(category)
    setIsSearching(true)
    handleFetchSearchOffers(1, category)
    dispatch(resetSearchOffers())
  }

  useEffect(() => {
    handleFetchRandomOffers(1)
  }, [user.telegramId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCatalogOffers())
      dispatch(resetSearchOffers())
    }
  }, [])

  useEffect(() => {
    if (refetchOffers) {
      if (isSearching) {
        handleFetchSearchOffers(1, search)
      } else {
        handleFetchRandomOffers(1)
      }
    }
  }, [refetchOffers, isSearching, search])

  if (catalogOffersLoading && !catalogOffers.length) {
    return <Loader />
  }

  return (
    <div className={"main-page"}>
      <div className={"d-flex-center-aligned gap-8 main-page__search"}>
        {isSearching ? (
          <button
            className={"d-flex-centered add-offer__heading--go-back"}
            onClick={() => {
              setIsSearching(false)
              setSearch("")
            }}
          >
            <LeftArrow />
          </button>
        ) : null}
        <Search searchText={search} handleSearch={handleSearch} />
        <button
          className={"main-page__search--favorites d-flex-column-centered"}
          onClick={() => {
            navigate("/favorites")
          }}
        >
          <HeartIcon />
        </button>
      </div>
      {!isSearching ? (
        <div>
          <Categories subCategoriesRef={subCategoriesRef} />
          <SubCategories
            onSubCategoryClick={onSubCategoryClick}
            subCategoriesRef={subCategoriesRef}
          />
        </div>
      ) : null}
      {isFreelancer && !isSearching ? (
        <div className={"d-flex-centered main-page__actions"}>
          <AddOfferBtn type={"button"} />
        </div>
      ) : null}
      {isSearching && (
        <div className={"container-padding"}>
          <p className={"main-page--search-text"}>Результат поиска:</p>
        </div>
      )}
      <div className={"main-page__cards"}>
        {offersList.length ? (
          <CardsList list={offersList} />
        ) : searchOffersLoading ? (
          <div className={"main-page__cards-loading d-flex-column-centered"}>
            <RingLoader />
          </div>
        ) : (
          <div className={"container-padding"}>
            <OffersEmptyState />
          </div>
        )}
        {newOffers.length && newOffers.length === perPage ? (
          <div className={"container-padding"}>
            <LoadMoreButton handleClick={handleLoadMore} isLoading={newOffersLoading} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default MainPage
