import React from "react"
import HeartIcon from "../../assets/images/svg/search-heart-icon.svg?react"
import HideIcon from "../../assets/images/svg/modal-hide-icon.svg?react"
import LeftArrow from "../../assets/images/svg/left-arrow.svg?react"
import "./PageHeadingStatus.scss"
import { useNavigate } from "react-router-dom"
interface PageHeadingStatusProps {
  type: "hidden" | "favorite"
}
const PageHeadingStatus: React.FC<PageHeadingStatusProps> = ({ type }) => {
  const navigate = useNavigate()
  return (
    <div className={"page-heading-status-wrapper container-padding d-flex-center-aligned gap-8"}>
      <button
        className={"d-flex-centered add-offer__heading--go-back"}
        onClick={() => navigate(-1)}
      >
        <LeftArrow />
      </button>
      <div className={" page-heading-status d-flex-centered flex-1"}>
        <div className={"page-heading-status--icon d-flex-column-centered"}>
          {type === "favorite" ? <HeartIcon /> : <HideIcon />}
        </div>
        <h1>{type === "favorite" ? "Избранные Офферы" : "Скрытые Офферы"}</h1>
      </div>
    </div>
  )
}

export default PageHeadingStatus
