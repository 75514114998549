import React from "react"
import "./LoadMoreButton.scss"
import RingLoader from "../loader/ring-loader/RingLoader"

interface ILoadMoreButton {
  handleClick: () => void
  isLoading: boolean
}
const LoadMoreButton: React.FC<ILoadMoreButton> = ({ handleClick, isLoading }) => {
  return (
    <button type={"button"} onClick={handleClick} className={"load-more-btn d-flex-centered gap-8"}>
      {isLoading ? <RingLoader /> : null}
      <span>Загрузить еще</span>
    </button>
  )
}

export default LoadMoreButton
