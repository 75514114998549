import React from "react"
import PageWithStatuses from "../../components/page-with-statuses/PageWithStatuses"

const HiddenPage = () => {
  return (
    <div className={"page-bottom-padding page-top-padding"}>
      <PageWithStatuses status={"hidden"} />
    </div>
  )
}

export default HiddenPage
