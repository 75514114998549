import React, { useState, useEffect } from "react"
import ImageUploading, { ImageListType } from "react-images-uploading"
import AddImageIcon from "../../../assets/images/svg/add-image.svg?react"
import EditIcon from "../../../assets/images/svg/edit-icon.svg?react"
import DeleteIcon from "../../../assets/images/svg/trash-icon.svg?react"
import { useAppDispatch } from "../../../hooks/redux-hooks/ReduxHooks"
import { uploadImages } from "../../../store/slices/file-upload"
import "./PortfolioUploading.scss"
import RingLoader from "../../shared/loader/ring-loader/RingLoader"
import { BASE_URL } from "../../../constants"
import { useSnackbar } from "notistack"

const MAX_FILE_SIZE_MB = 5

const PortfolioUploading = ({
  setPortfolioIds,
  defaultValue = [],
  editMode = false,
}: {
  setPortfolioIds: React.Dispatch<React.SetStateAction<number[]>>
  defaultValue?: { id: number; key: string; url: string }[]
  editMode?: boolean
}) => {
  const [images, setImages] = useState<any[]>([])
  const maxNumber = 10
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onChange = async (imageList: ImageListType, id: any) => {
    if (isUploading) return
    setIsUploading(true)

    const invalidFiles = imageList.filter(
      (image) =>
        (image.file && image.file.size > MAX_FILE_SIZE_MB * 1024 * 1024) ||
        (image.file && !image.file.type.includes("image/")),
    )

    if (invalidFiles.length > 0) {
      if (invalidFiles.length === 1) {
        if (!invalidFiles[0].file?.type.includes("image/")) {
          enqueueSnackbar("Файл имеет неверный формат. Поддерживаются только изображения.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
          })
        } else {
          enqueueSnackbar("Файл превышает 5 МБ.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
          })
        }
      } else {
        enqueueSnackbar("Один или более файлов превышают 5 МБ или имеют неверный формат.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
        })
      }
      setIsUploading(false)
      return
    }

    let oldState = imageList.map((image) => ({
      data_url: image.data_url,
      file: image.file,
      loading: false,
    }))

    const newImages = id.map((index: number) => oldState[index])
    setImages(imageList.reverse())

    for (const [index, image] of newImages.entries()) {
      if (image.file) {
        setImages((current) => {
          const newState = [...current]
          newState[index].loading = true
          return newState
        })

        try {
          const response: any = await dispatch(uploadImages(image.file)).unwrap()
          setPortfolioIds((prevState) => [...prevState, response?.id])
          setImages((current) => {
            const newState = [...current]
            newState[index].loading = false
            newState[index].id = response?.id
            return newState
          })
        } catch (error) {
          setImages((current) => {
            const newState = [...current]
            newState[index].loading = false
            return newState
          })
        }
      }
    }
    setIsUploading(false)
  }

  const handleImageRemove = (index: number, id: number) => {
    const newImageList = images.filter((_, i) => i !== index)
    setImages(newImageList)
    setPortfolioIds((prevState) => prevState.filter((item: any) => item != id))
  }

  useEffect(() => {
    if (editMode && defaultValue.length) {
      const initialImages = defaultValue.map((image) => ({
        id: image.id,
        data_url: `${BASE_URL}${image.url}`,
        loading: false,
      }))
      setImages(initialImages)
      setPortfolioIds(defaultValue.map((image) => image.id))
    }
  }, [editMode, defaultValue, setPortfolioIds])

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey='data_url'
      acceptType={[]}
    >
      {({ onImageUpload, onImageUpdate }) => (
        <div className='portfolio-uploading d-flex gap-8'>
          <div
            className='d-flex-centered add-offer-form__item--upload add-offer-form__item--upload-portfolio'
            onClick={onImageUpload}
          >
            <AddImageIcon />
          </div>
          {images.map((image, index) => (
            <div key={index} className={"portfolio-uploading-image"}>
              {image.loading ? (
                <div className={"portfolio-uploading-image-loader d-flex-centered"}>
                  <RingLoader />
                </div>
              ) : (
                <div>
                  <div className={"portfolio-uploading-image-wrapper"}>
                    <img src={image.data_url} alt='' width='100' />
                  </div>
                  <div className={"portfolio-uploading-image-wrapper-btns d-flex-center-aligned"}>
                    <button
                      type={"button"}
                      className={"d-flex-centered"}
                      onClick={() => {
                        setPortfolioIds((prevState) => prevState.filter((_, i) => i !== index))
                        onImageUpdate(index)
                      }}
                    >
                      <span>Редактировать</span>
                      <EditIcon />
                    </button>
                    <button
                      type={"button"}
                      className={"d-flex-centered"}
                      onClick={() => handleImageRemove(index, image.id)}
                    >
                      <span>Удалить</span>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  )
}

export default PortfolioUploading
